import React, { useState, useEffect } from "react";
import "../assets/style/globalStyle.scss";
import Footer from "../components/footer";
import NavBar from "../components/navBarSite";


const styles = {
  title: {
    fontWeight: 900,
    fontSize: 20,
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
  },
  subtitle: {
    fontWeight: 600,
    fontSize: 18,
  },
}

export function UseMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

const privacyPage = () => {
  const isMobile = UseMedia('(max-width: 1200px)');

  return (
    <div style={{ margin: 0, padding: 0, paddingTop: 120, fontFamily: "Montserrat", }}>
      <NavBar></NavBar>
      <div style={{ paddingRight: isMobile ? 26 : 60, paddingLeft: isMobile ? 26 : 60 }}>
        <p style={styles.title}>
          DÉCLARATION DE CONFIDENTIALITÉ
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 1 - PRÉAMBULE
        </p>
        <p
          style={styles.text
          }
        >
          Cette politique de confidentialité s’applique à l’application
          mobile : Ball’N Connect (ci-après désignée par le terme “Service”).
          {"\n"}{"\n"}
          Cette page vous explique nos politiques en matière de collecte,
          d’utilisation et de communication des données à caractère personnel
          lorsque vous utilisez notre Service ainsi que les choix qui s’offrent
          à vous en ce qui concerne ces données.
          {"\n"}{"\n"}
          Nous utilisons vos données pour fournir et améliorer le Service.
          En utilisant le Service, vous consentez à la collecte
          et à l’utilisation d’informations conformément à la présente politique.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 2 - DÉFINITIONS
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <span
            style={styles.text
            }
          >
            <span style={styles.subtitle}>
              {"\u2022"} Service : {""}
            </span>
            Par Service on entend l’application mobile Ball’N Connect
          </span>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <span
            style={styles.text
            }
          >
            <span style={styles.subtitle}>
              {"\u2022"} Données à caractère personnel : {""}
            </span>
            Données à Caractère Personnel désigne des données concernant
            un individu vivant qui peut être identifié à partir de ces données
            ou identifiable
          </span>
        </div>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 3 - PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES
        </p>
        <p
          style={{
            ...styles.text,

          }}
        >
          Conformément aux dispositions de l’article 5 du Règlement général sur la protection des données,
          la collecte et le traitement des données des utilisateurs de l’application respectent
          les principes suivants :
          {"\n"}
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Licéité, loyauté et transparence : les données ne peuvent être collectées et
            traitées qu’avec le consentement de l’utilisateur propriétaire des données
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p>{"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Finalités déterminées : la collecte et le traitement des données
            sont exécutés pour répondre à un ou plusieurs objectifs déterminés
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Minimisation des données : seules les données nécessaires
            à la bonne exécution des objectifs poursuivis par le site sont collectées
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Conservation des données réduites dans le temps
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Intégrité et confidentialité des données collectées et traitées
          </p>
        </div>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 4 - DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES
        </p>
        <p style={{ ...styles.title, marginBottom: 10 }}>
          A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE
        </p>
        <p
          style={{
            ...styles.text,

          }}
        >
          Lorsque vous utilisez notre Service, il est possible que nous vous
          demandions de nous fournir certaines données personnelles nominatives
          qui peuvent servir à vous contacter ou à vous identifier (“Données à Caractère Personnel”).
          Les données à caractère personnel collectées sur l’application mobile Ball’N Connect
          sont les suivantes :
          {"\n"}
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Nom prénom
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Date de naissance
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Adresse e-mail
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Adresse postale
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Taille/ Poids
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Données de localisation
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Coordonnées bancaires
          </p>
        </div>
        <p
          style={{
            ...styles.text,

          }}
        >
          {"\n"}
          Les données sont collectées lorsque vous vous inscrivez sur l’application.
          {"\n"}
          {"\n"}
          Les données collectées et traitées par l’application sont exclusivement hébergées
          et traitées en France.
        </p>
        <p style={{ ...styles.title, marginTop: 10, marginBottom: 10 }}>
          B. HÉBERGEMENT DES DONNÉES
        </p>
        <p
          style={{
            ...styles.subtitle,

          }}
        >
          L’application mobile Ball’N Connect est hébergée par :
          {"\n"}
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Plateformes mobiles : Apple Store et Google Play
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Heroku
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Netlify
          </p>
        </div>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 5 - FINALITÉS
        </p>

        <p
          style={{
            ...styles.subtitle,

          }}
        >
          La collecte et le traitement des données répondent aux finalités suivantes :
          {"\n"}
        </p>

        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour créer et gérer votre compte
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour fournir et assurer notre Service
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour vous adresser des communications pertinentes
            et adaptées à vos besoins directement en lien avec les services du site
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour faire évoluer nos services pour vous offrir de nouvelles fonctionnalités
            et nous adapter à vos besoins
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour assurer l’assistance client
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour contrôler l’utilisation de notre Service
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Pour détecter, prévenir et régler les problèmes techniques
          </p>
        </div>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 6 - RESPONSABLE DU TRAITEMENT DES DONNÉES
        </p>

        <p
          style={{
            ...styles.text,

          }}
        >
          Ball’ N Connect traite la plupart de vos données personnelles dans
          le cadre du contrat que vous avez conclu lors de votre inscription
          sur l’application via l’acceptation de nos conditions générales d’utilisation.
          {"\n"}
          Toutefois, nous sommes susceptibles de traiter certaines données vous concernant
          sur la base de votre consentement, en raison d’obligations légales ou pour répondre
          à notre intérêt légitime à les traiter.
          {"\n"}
          {"\n"}
          Le responsable du traitement conservera dans ses systèmes informatiques de l’application
          et dans des conditions raisonnables de sécurité l’ensemble des données collectées pour
          une durée de :  3 ans maximum en cas d’inactivité de l’utilisateur ou jusqu’au
          paiement complet en cas de paiement unique effectué sur l’application.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 7 - DESTINATAIRES
        </p>


        <p
          style={{
            ...styles.text,

          }}
        >
          Ball’N Connect est destinataire des données à caractère personnelle recueillies.
          {"\n"}
          {"\n"}
          Nous pourrions également être susceptibles de recourir à des fournisseurs tiers
          qui vont uniquement recueillir, utiliser et divulguer vos renseignements dans
          la mesure du nécessaire pour pouvoir réaliser les services qu’ils nous fournissent.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 8 - DROITS DE L’UTILISATEUR
        </p>

        <p
          style={{
            ...styles.text,

          }}
        >
          Conformément à la réglementation concernant le traitement des données
          à caractère personnel, l’utilisateur possède les droits ci-après énumérés :
          {"\n"}
          {"\n"}
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Droit d’accès, de rectification et droit à l’effacement
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Droit à la portabilité des données
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Droit à la limitation et l’opposition du traitement des données
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Droit de saisir l’autorité de contrôle compétente
          </p>
        </div>
        <p
          style={{
            ...styles.text,

          }}
        >
          {"\n"}
          {"\n"}
          Le responsable du traitement des données est tenu de répondre à l’utilisateur
          dans un délai de 30 (trente) jours maximum.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 9 - CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ
        </p>

        <p
          style={{
            ...styles.text,

          }}
        >
          L’éditeur de l’application se réserve le droit de la modifier
          afin de garantir sa conformité avec le droit en vigueur.
          {"\n"}
          {"\n"}
          Par conséquent, l’utilisateur est invité à venir consulter régulièrement
          cette politique de confidentialité afin de se tenir informé
          des derniers changements qui lui seront apportés.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          ARTICLE 10 - VIE PRIVÉE DES ENFANTS
        </p>

        <p
          style={{
            ...styles.text,

          }}
        >
          Notre Service ne s’adresse pas aux personnes de moins de 18 ans (“Enfants”).
          {"\n"}
          {"\n"}
          Nous ne recueillons pas sciemment de données personnelles nominatives
          auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur
          et que vous savez que votre Enfant nous a communiqué des Données à Caractère Personnel,
          veuillez nous contacter. Si nous apprenons que nous avons recueilli
          des Données à Caractère Personnel auprès d’enfants sans vérifier s’il y a
          consentement parental, nous faisons le nécessaire pour supprimer
          ces informations de nos serveurs.
        </p>

        <p style={{ ...styles.title, marginTop: 30, marginBottom: 10 }}>
          Nous contacter
        </p>

        <p
          style={{
            ...styles.text,

          }}
        >
          Pour toute question relative à la présente Politique de Confidentialité,
          veuillez nous contacter:
          {"\n"}
          {"\n"}
        </p>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }}
          >
            Par courrier électronique:                     <a
              href={"mailto:contact@ballnconnect.com"}
              style={{ color: "#8a508b" }}
            >
              contact@ballnconnect.com
            </a>
          </p>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', marginLeft: 10, marginBottom: 45 }}>
          <p> {"\u2022"}</p>
          <p
            style={{
              ...styles.text,

            }
            }
          >
            Par courrier : 2 rue Auguste Blanqui 93170 Bagnolet
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div >
  );
};

export default privacyPage;
